import * as React from "react";
import { useState, useRef } from "react";
import useIsClient from "../hooks/use-is-client";

interface ReadMoreProps {
  text: string;
  maxLength: number;
  className: string;
  buttonText?: string;
}

const ReadMore: React.FC<ReadMoreProps> = ({
  text,
  maxLength,
  className,
  buttonText,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isClient, key } = useIsClient();
  const ref = useRef<HTMLDivElement | null>(null);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);

    isExpanded === true
      ? ref.current.scrollIntoView({ behavior: "smooth" })
      : "";
  };

  function truncate(str, no_words) {
    return str.split(".").splice(0, no_words).join(".");
  }

  const displayText = isExpanded ? text : truncate(text, maxLength) + ".";

  return (
    <div className={className} ref={ref}>
      {isClient && (
        <div className="text-wrap">
          {displayText.split(".").length > maxLength ? displayText : text}
        </div>
      )}
      {isClient && displayText.split(".").length > maxLength && (
        <button
          onClick={toggleExpanded}
          className="text-lightblue bg-transparent no-border p-0 mt-3"
        >
          {isExpanded ? "Close" : buttonText ? buttonText : "Read more"}
        </button>
      )}
    </div>
  );
};

export default ReadMore;
